<template>
    <div class="page">
        <div class="page-header pd">
            <sc-button icon="chevron-left" @click="$router.push(`/app/${resource.route}/index`)">Back</sc-button>
        </div>

        <div class="pd">
            <sc-form :form="form" @submit="submit()" v-if="form">

                <template #field-url>
                    <sc-form-field :form="form" field="url" name="URL*" description="Start with http:// or https://">
                        <input v-model="form.fields.url" placeholder="https://www.example.com"/>
                    </sc-form-field>
                </template>

                <template #field-description>
                    <sc-form-field :form="form" field="description">
                        <textarea v-model="form.fields.description"></textarea>
                    </sc-form-field>
                </template>
            </sc-form>
        </div>
    </div>

</template>

<script>
export default {
  name: "new",

  props: {
    passed: {
      required: true,
    }
  },

  data() {
    return {
      form: null,
      systemTemplates: [],
    }
  },

  computed: {
    resource() {
      return this.passed
    },

    currentTemplate() {
      if (this.systemTemplates.length > 0) {
        return this.systemTemplates.find((t) => t.id === this.form.fields.template_id);
      }
    }
  },

  mounted() {
    this.form = this.$sform.createForm({
      name: `New ${this.resource.name} Page`,
      url: this.resource.api + '/page',
      fields: {
        name: {
          name: 'Name*'
        },
        url: {},
        description: {},
      },
      success: () => {
        this.$notify.success(`${this.resource.name} added`)
        this.$router.push(`/app/${this.resource.route}/index`)
      }
    })
  },

  methods: {
    submit() {
      this.form.post();
    },
  }
}
</script>

<style scoped lang="scss">

</style>